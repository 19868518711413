(function ($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};
  // --------
  // Primary behavior responsible for initializing the welcome15 offer logic

  Drupal.behaviors.ELB_welcome15 = {
  // debug config
    debug: false, // when debugging, the overlay opens on all page loads
    showThanks: true, // even if there's an error, show the "thank you" message
    showSignedUp: false, // even if there's an error, show the "already signed up" message

    // storage cookie name
    offerCookie: 'welcome15',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // template path config
    templates: {
      form: 'welcome_15',
      // already_signed_up: 'welcome15_offer_signed_up', Not used, always show the success regardless of if signed up before or not
      thanks: 'welcome_15_success',
      error: 'welcome15_offer_error',
      disclaimer: 'welcome15_offer_disclaimer'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'welcome15-overlay',
        transition: 'none',
        height: 'auto',
        trapFocus: false
      },
      mobile: {
        className: 'welcome15-overlay',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        opacity: '0',
        transition: 'none',
        trapFocus: false
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
   * Helper function to get the raw contents of an JS inline template, and optionally interpolate
   * the html using Mustache
   */
    getTemplateContent: function (key, data) {
      var undef;

      // Sanitize the incoming data
      path = key !== undef ? key : 'foobar_template';
      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length == 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data == false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      // Add welcome15 body CSS class
      $('body').addClass('welcome15');

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
        html: content,
        height: '352px',
        maxWidth: '100%',
        maxHeight: '100%',
        width: '461px'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function () {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (useAltMarkUp) {
          cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcome15-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, {
        // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
        // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcome15.initSignUpForm();
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }
          }
        });
      }, self.defaultTimeout);
    },

    /**
   * Helper function to determine if the welcome15 offer should appear on this page
   * @TODO: this will likely be expanded to be disabled on checkout and whatnot
   */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false,
        undef;
      // adding to allow manual disabling of the popover
      var disabled = 0;

      if (hasCookie == undef) {
      // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
        var $markUp = $(self.getTemplateContent(self.templates.form));
        var markUpDisabled = $markUp.find('#DISABLE_POPOVER');
        var disableOfferPopover = $markUp.hasClass('js-disable-offer-popover');
        var disableUrlString = $markUp.attr('data-disable-links');
        var blockPopover = $markUp.hasClass('js-block-popover');
        var disablePaths = false;
        var currentPath = window.location.pathname;
        var disableUrlArray = [];
        var disableUrlArrayLength;

        if (typeof disableUrlString !== 'undefined') {
          disableUrlArray = disableUrlString.split(',');
          disableUrlArrayLength = disableUrlArray.length;
          if (currentPath === '/') {
            currentPath = currentPath + 'home';
          }

          for (var i = 0; i < disableUrlArrayLength; i++) {
            if (disableUrlArray[i].indexOf(currentPath) !== -1) {
              disablePaths = true;
              break;
            }
          }
        }

        if (disablePaths && blockPopover) {
          return false;
        }
        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0 || disableOfferPopover || disablePaths) {
          $.cookie(cookieName, '1', {
            expires: 1,
            path: '/'
          });

          return false;
        }
      }

      if (self.debug || hasCookie == undef) {
        showOffer = true;
      }

      var popupShowAtVisitCount = Drupal.settings.globals_variables.popup_show_at_visit_count || false;

      // 'RPC:RESULT' event used for getting cp.utag_main__pn value
      if (popupShowAtVisitCount) {
        showOffer = false;
        $(document).on('RPC:RESULT', function () {
          if (Number(window.site.trackingDataLayer.data['cp.utag_main__pn']) === popupShowAtVisitCount) {
            self.showSignupForm();
          }
        });
      }

      return showOffer;
    },

    // Main function to get things kicked off
    attach: function (context, settings) {
      var self = this,
        cookieName = self.offerCookie;

      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
      // this.showSignupForm();
      // Don't show the offer popup on the professional and location page
        if (!$('body').hasClass('section-professional') && !$('body').hasClass('section-locations') && !$('body').hasClass('get_facts')) {
          this.showSignupForm();
          $.cookie(cookieName, '1', {
            expires: 1,
            path: '/'
          });
        }
      }

      // Bind to the email_signup.success event
      // When a user does the email sign up in the footer, we'll show them the welcome15 overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('email_signup.success', function (event, rpcResponse) {
        var response = rpcResponse.getData(),
          userinfo = response.userinfo || {},
          templates = self.templates,
          content = '';
        var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;

        // commenting this out for now - might want to show already_signed_up again in the future
        // .. only show the overlay if the user hasn't already registered
        // if (previously_opted_in_email_promotion == 0) {
        content = templates.thanks;
        // } else {
        //  content = templates.already_signed_up;
        // }

        if (content.length > 0) {
        // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);

          self.openWindow(html, {
            onComplete: function () {
              $.colorbox.resize();
            }
          });
        }
      });

      // Bind to the email_signup.error event for no particular reason except that it exists
      // Do we need to show an error message?
      // $(context).on('email_signup.error', function(event, rpcResponse) {});

      // Bind to the cbox_complete event. This fires anytime a colorbox window is opened.
      // This event handler adds the disclaimer text to the overlays, but could be used for similiar tasks as well.
      // $(context).on('cbox_complete', function() {
      // var overlay  = ($('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc);
      // $element = $('#cboxContent', '#colorbox.' + overlay.className.split(" ")[0]);
      // });
      $('body').on('click', '.site-email-signup__success--welcome-15 .form-submit', function () {
        var dest = $('.site-email-signup__success--welcome-15 .form-submit').attr('data-dest');

        dest = dest || '';
        if (/^\/.+$/.test(dest)) {
          dest = dest.replace(/^(\s|\/)+/, '');
        }
        if (/^(?!http|\/).+$/.test(dest)) {
          dest = Drupal.settings.basePath + dest;
        }
        var email = document.getElementsByName('search-first');
        var emailvalue = email[0].value;

        if (emailvalue != $('#default').val() && emailvalue != '') {
          dest = dest + '?email=' + escape(emailvalue);
          $('.errormessage').addClass('hidden');
          window.location.href = dest;
        } else {
          $('.offers-email-signup-form__errors-first').removeClass('hidden');
        }

        return false;
      });
    }
  };

  // --------
  // Callbacks and whatnot

  site.offers.welcome15 = {
    behavior: Drupal.behaviors.ELB_welcome15,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    /**
   * Initialize the signup form
   */
    initSignUpForm: function () {
      var self = site.offers.welcome15,
        overlay = self.getOverlayContent();

      // we set visibility to 0 onOpen, to avoid "sliding" while the css is loading
      // now we will:
      // 1) fade them both out
      $('.welcome15-overlay #cboxContent,#cboxOverlay').fadeOut(1, function () {
        // 2) set visibility on and fade in the overelay
        $('#cboxOverlay').css('visibility', 'visible').fadeIn(500, function () {
          // 3) set visibility and fade in the content (very quickly)
          $('.welcome15-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
        });
      });

      overlay.find('form').submit(function (e) {
        e.preventDefault();

        var form = this;
        var fields = [
          '_SUBMIT',
          'LAST_SOURCE',
          'COUNTRY_ID',
          'LANGUAGE_ID',
          'PC_EMAIL_PROMOTIONS',
          'redirect_or_text',
          'redirect',
          'PC_EMAIL_ADDRESS',
          'FIRST_NAME'
        ];
        var paramObj = {};

        $.each(fields, function (i, name) {
          var field = $('input[name="' + name + '"]', form),
            undef;

          if (field != undef && field.length > 0) {
            paramObj[name] = field.val();
          }
        });

        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            var response = jsonRpcResponse.getData(),
              userinfo = response.userinfo || {};
            var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
            var b = self.behavior,
              t = b.templates,
              c = t.error, // Default template to return
              e = true, // Defaults to the error condition
              d = { message: "<p>There's been an error.</p>" }; // Error message

            // Figure out if the user has already signed up. If so, show 'em the already_signed_up template
            // c = (previously_opted_in_email_promotion == 0) ? t.thanks : t.already_signed_up;
            c = t.thanks;

            // And make sure there's no error to show
            e = false;

            // Remove any existing content, and show the error/thanks/already_signed_up template
            overlay
              .empty()
              .append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
            $.colorbox.resize();

          // Act like we're opening a new colorbox
          // $(document).trigger('cbox_complete');
          },
          onFailure: function (jsonRpcResponse) {
          // Store messages and display them in error div element.
            var messages = jsonRpcResponse.getMessages();

            $('.welcome-15').toggleClass('error-msg');
            $('.js-popup-offer-error-messages').empty().html('<p>' + messages[0].text + '</p>').removeClass('hidden');
            $('.js-input-pc-email').addClass('error');
            generic.focusErrors($('.js-popup-offer-error-messages'), $('.js-email_input'));
          }
        });

        return false;
      });
    }
  };
})(jQuery);
